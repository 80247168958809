<template>
  <div class="page-container">
    <div class="header-body">
      <div></div>
    </div>
    <div class="table-body">
      <el-card>
        <el-table show-summary :data="promoterList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50"> </el-table-column>
          <el-table-column prop="schName" label="学校"> </el-table-column>
          <el-table-column prop="nums" label="报名人数"> </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getSchoolSignUpTotal } from '@/api/api.js'

export default {
  data () {
    return {
      promoterList: []
    }
  },
  created () {
    this.getSchoolSignUpTotal()
  },
  methods: {
    /* 获取推广员列表 */
    getSchoolSignUpTotal () {
      getSchoolSignUpTotal().then(res => {
        this.promoterList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-container {
  .header-body {
    .add-btn {
      text-align: right;
    }
  }
  .pagination-body {
      .el-pagination {
        text-align: center;
        padding: 20px 0;
      }
    }
}
</style>
